import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import './styles/Login.css';

import logo from '../imgs/logo.png';
import delphus from '../imgs/delphus.png';

const swal = withReactContent(Swal)

function Login() {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 })));   

    useEffect(()=>{
        if (auth.authenticated) window.location.href = '/ejr/home'
    }, [auth])

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function(value, key){
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/login', object)
        .then(function (response) {
            console.log(response.data[0])
            let { pk_usu, nome, ultimo_login, fk_gpu } = response.data[0];
            if (pk_usu && pk_usu > 0) {
                console.log(response.data.token)
                api.defaults.headers.common['token'] = response.data.token;
                localStorage['authejr'] =  JSON.stringify({ authenticated: true, user: pk_usu, name: nome, ultimo_login, last_activity: new Date(), grupo: fk_gpu, access_token: response.data.token })
                window.location.href = '/ejr/home?login=true' 
            } else {
                swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            }
        })
        .catch(function (error) {
            console.log(error)
            swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
        });
    }

    return (
        <div className="Login">
            <div className="form">
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <img alt="logo" src={logo} title="ejr" className="logo"/>
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário"/>
                        <label htmlFor="login">Login</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" id="senha" name="senha" className="loginInput" placeholder="Senha"/>
                        <label htmlFor="senha">Senha</label>
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Entrar</p></span>
                    </button>
                    <div className="forgotPwDiv">
                        <a href='/ejr/forgotPw'>Esqueci minha senha</a>
                    </div>

                </form>
            </div>
            <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div>
        </div>
    );
}

export default Login;
