import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrTextField from '../components/FrTextField'

import api from '../services/api'
import { dateSql, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Icon } from 'react-icons-kit'
import FrDatePicker from '../components/FrDatePicker';
import FrTableReg from '../components/FrTableReg'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
const moment = require("moment");

const swal = withReactContent(Swal)

function Visitas() {
  const [data, setData] = useState({ ativo: 'S' })
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [dataVit, setDataVit] = useState([{ nomencf: '', display: '' }])
  const [comboNcf, setComboNcf] = useState([{ value: '', display: '' }])
  const [fk_ncf, setfk_ncf] = useState('')
  const [nomencf, setnomencf] = useState('')
  const [prazo_resolucao, setprazo_resolucao] = useState('')
  const [data_resolucao, setdata_resolucao] = useState('')
  const [providencia, setprovidencia] = useState('')
  const [observacoes, setobservacoes] = useState('')  
  const [detalhamento_nao_conforme, setdetalhamento_nao_conforme] = useState('')
  const [modal, setModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [pkModal, setPkModal] = useState(0)
  const [comboCli, setComboCli] = useState([{ value: '', display: '' }])
  const [comboSvi, setComboSvi] = useState([{ value: '', display: '' }])

  const columns = [{ id: 'nomencf', numeric: false, label: 'Tipo Não Conformidade', align: 'left', minWidth: 100 },
  { id: 'providencia', numeric: false, label: 'Providência', align: 'left', minWidth: 100 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Visitas', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Visitas', 'Inclusao'))
      }

      setComboNcf(await api.getComboData('tipo_nconformidade'))

      setComboCli(await api.getComboData('cliente'))

      setComboSvi(await api.getComboData('situacao_visita'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/visitas?pk_vis=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])

            api.get('/visitas/itens?pk_vis=' + query.codigo).then(r => {
              console.log(r.data)
              if (r.data.length > 0){
                setDataVit(r.data)
              } else {
                setDataVit([{ nomencf: '', display: '' }])
              }
                
            })

            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_cli'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr == 'fk_cli')
        fieldName = 'Cliente'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.dataVit = dataVit
        if (auxValues.data_prevista)
          auxValues.data_prevista = dateSql(auxValues.data_prevista)
        if (auxValues.data_realizacao)
          auxValues.data_realizacao = dateSql(auxValues.data_realizacao)

        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/visitas/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/visitas/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  function mostraModal(e) {
    e.preventDefault()
    setfk_ncf(null)
    setnomencf(null)
    setdata_resolucao(null)
    setprazo_resolucao(false)
    setprovidencia(null)
    setobservacoes(null)
    setdetalhamento_nao_conforme(null)
    setModal(true)
  }

  function closeModal(e) {
    e.preventDefault()
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault()
    if (!fk_ncf) {
      swal.fire({
        title: 'Erro!',
        text: "Informe a não conformidade!",
        icon: 'warning',
      })

    } else {
      if (!updatemodal)
        incluiVit(e)
      else
        updateVit(e)
      closeModal(e)
    }
  }


  const handleChangeModal = (e) => {

    console.log(e.target.name, e.target.value)

    if (e.target.name == 'fk_ncf') {
      setfk_ncf(e.target.value)
      api.get('/tipo_nconformidade?pk_ncf=' + e.target.value).then(r => {
        setnomencf(r.data[0].descricao)
      })

    }
    if (e.target.name == 'data_resolucao')
      setdata_resolucao(e.target.value)

    if (e.target.name == 'prazo_resolucao')
      setprazo_resolucao(e.target.value)

    if (e.target.name == 'providencia')
      setprovidencia(e.target.value)

    if (e.target.name == 'observacoes')
      setobservacoes(e.target.value)

    if (e.target.name == 'detalhamento_nao_conforme')
      setdetalhamento_nao_conforme(e.target.value)

  };


  function incluiVit(e) {
    e.preventDefault();
    let pk = 1

    if ((dataVit[0].nomencf === '') || (dataVit[0].display === '')) {
      setDataVit([{
        prazo_resolucao: (prazo_resolucao) ? prazo_resolucao : null,
        data_resolucao: (data_resolucao) ? data_resolucao : null,
        format_prazo_resolucao: (prazo_resolucao) ? moment(prazo_resolucao).format('DD/MM/YYYY') : '',
        format_data_resolucao: (data_resolucao) ? moment(data_resolucao).format('DD/MM/YYYY') : '',
        providencia: providencia,
        observacoes: observacoes,
        detalhamento_nao_conforme: detalhamento_nao_conforme,
        fk_ncf: fk_ncf,
        nomencf: nomencf,
        pk: pk
      }])

    } else {

      let item = [{
        prazo_resolucao: dataVit[0].prazo_resolucao,
        data_resolucao: dataVit[0].data_resolucao,
        format_prazo_resolucao: dataVit[0].format_prazo_resolucao,
        format_data_resolucao: dataVit[0].format_data_resolucao,
        providencia: dataVit[0].providencia,
        observacoes: dataVit[0].observacoes,
        detalhamento_nao_conforme: dataVit[0].detalhamento_nao_conforme,
        fk_ncf: dataVit[0].fk_ncf,
        nomencf: dataVit[0].nomencf,
        pk: pk

      }]
      pk++
      var i;
      for (i = 1; i < dataVit.length; i++) {
        item.push({
          prazo_resolucao: dataVit[i].prazo_resolucao,
          data_resolucao: dataVit[i].data_resolucao,
          format_prazo_resolucao: dataVit[i].format_prazo_resolucao,
          format_data_resolucao: dataVit[i].format_data_resolucao,
          providencia: dataVit[i].providencia,
          observacoes: dataVit[i].observacoes,
          detalhamento_nao_conforme: dataVit[i].detalhamento_nao_conforme,
          fk_ncf: dataVit[i].fk_ncf,
          nomencf: dataVit[i].nomencf,
          pk: pk

        })
        pk++
      }
      item.push({
        data_resolucao: (data_resolucao) ? data_resolucao : null,
        prazo_resolucao: (prazo_resolucao) ? prazo_resolucao : null,
        format_data_resolucao: (data_resolucao) ? moment(data_resolucao).format('DD/MM/YYYY') : '',
        format_prazo_resolucao: (prazo_resolucao) ? moment(prazo_resolucao).format('DD/MM/YYYY') : '',
        providencia: providencia,
        observacoes: observacoes,
        detalhamento_nao_conforme: detalhamento_nao_conforme,
        fk_ncf: fk_ncf,
        nomencf: nomencf,
        pk: pk

      })
      pk++
      setDataVit(item)
    }
  }

  function updateVit(e) {
    e.preventDefault();

    let item = []
    let i

    for (i = 0; i < dataVit.length; i++) {
      if (dataVit[i].pk == pkModal) {
        item.push({
          data_resolucao: (data_resolucao) ? data_resolucao : null,
          prazo_resolucao: (prazo_resolucao) ? prazo_resolucao : null,
          format_data_resolucao: (data_resolucao) ? moment(data_resolucao).format('DD/MM/YYYY') : '',
          format_prazo_resolucao: (prazo_resolucao) ? moment(prazo_resolucao).format('DD/MM/YYYY') : '',
          providencia: providencia,
          observacoes: observacoes,
          detalhamento_nao_conforme: detalhamento_nao_conforme,
          fk_ncf: fk_ncf,
          nomencf: nomencf,
          pk: pkModal
        })
      } else {
        item.push({
          data_resolucao: (dataVit[i].data_resolucao) ? dataVit[i].data_resolucao : null,
          prazo_resolucao: (dataVit[i].prazo_resolucao) ? dataVit[i].prazo_resolucao : null,
          format_data_resolucao: (dataVit[i].data_resolucao) ? moment(dataVit[i].data_resolucao).format('DD/MM/YYYY') : '',
          format_prazo_resolucao: (dataVit[i].prazo_resolucao) ? moment(dataVit[i].prazo_resolucao).format('DD/MM/YYYY') : '',
          providencia: dataVit[i].providencia,
          observacoes: dataVit[i].observacoes,
          detalhamento_nao_conforme: dataVit[i].detalhamento_nao_conforme,
          fk_ncf: dataVit[i].fk_ncf,
          nomencf: dataVit[i].nomencf,
          pk: dataVit[i].pk,
        })
      }

      if (i + 1 == dataVit.length)
        setDataVit(item)
    }

  }


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Vistorias</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrSelect
            value={data.fk_cli || []}
            style={{ width: 400 }}
            name="fk_cli"
            id="fk_cli"
            label='Cliente'
            data={comboCli}
            onChange={handleChange}
          />
          <br/>
          <FrDatePicker
            maxLength='10'
            value={(data.data_prevista || '').split('T')[0]}
            onChange={handleChange}
            name="data_prevista"
            id="data_prevista"
            color="#49573B"
            label="Data Prevista"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrDatePicker
            maxLength='10'
            value={(data.data_realizacao || '').split('T')[0]}
            onChange={handleChange}
            name="data_realizacao"
            id="data_realizacao"
            color="#49573B"
            label="Data Realização"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrSelect
            value={data.fk_svi || []}
            style={{ width: 150 }}
            name="fk_svi"
            id="fk_svi"
            label='Situação'
            data={comboSvi}
            onChange={handleChange}
          />
          <br />

          <FrTextInput
            maxLength='300'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#9c9595"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: 600 }}
            multiline={true}
            rows={5}
            maxRows={5}
          />

          <br />
          <div className="grupoTitulo">
            Não Conformidades
          </div>

          <div className="grupoEdits">

            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div className="manutReg" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15}}>

              <FrTableReg 
                setModal={setModal}
                setUpdateModal={setUpdateModal}
                editar={true}
                setPkModal={setPkModal}
                setDataGrid={setDataVit}
                setdata_resolucao={setdata_resolucao}
                setprazo_resolucao={setprazo_resolucao}
                setprovidencia={setprovidencia}
                setdetalhamento_nao_conforme={setdetalhamento_nao_conforme}
                setobservacoes={setobservacoes}
                setfk_ncf={setfk_ncf}
                setnomencf={setnomencf}
                detail
                regName=""
                columns={columns}
                searched={false}
                codeName="pk"
                page={''}
                data={dataVit} 
              />
              <div className="register" hidden={!modal} style={{ marginLeft: 0, marginTop: 8, paddingTop: 15}}>

                <div className="fields" style={{display: 'flex', flexDirection: 'column',}}>
                  <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <FrSelect
                      value={fk_ncf || []}
                      style={{ minWidth: '200px' }}
                      name="fk_ncf"
                      id="fk_ncf"
                      label='Não Conformidade'
                      data={comboNcf}
                      onChange={handleChangeModal}
                    />
                    <FrDatePicker
                      maxLength='10'
                      value={(prazo_resolucao || '').split('T')[0]}
                      onChange={handleChangeModal}
                      name="prazo_resolucao"
                      id="prazo_resolucao"
                      color="#49573B"
                      label="Prazo Resolução"
                      variant="outlined"
                      size="small"
                      style={{ width: 200 }}
                    />
                    <FrDatePicker
                      maxLength='10'
                      value={(data_resolucao || '').split('T')[0]}
                      onChange={handleChangeModal}
                      name="data_resolucao"
                      id="data_resolucao"
                      color="#49573B"
                      label="Data Resolução"
                      variant="outlined"
                      size="small"
                      style={{ width: 200 }}
                    />
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <FrTextField 
                      maxLength='5000'
                      value={detalhamento_nao_conforme || ''}
                      onChange={handleChangeModal}
                      name="detalhamento_nao_conforme"
                      id="detalhamento_nao_conforme"
                      color="#49573B"
                      label="Detalhes de Não Conformidade"
                      variant="outlined"
                      size="small"
                      multiline={true}
                      rows={7}
                      maxRows={7}
                      style={{ width: '100%'  }}
                      startAdornment={true}
                    />
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <FrTextField
                      maxLength='5000'
                      value={providencia || ''}
                      onChange={handleChangeModal}
                      name="providencia"
                      id="providencia"
                      color="#49573B"
                      label="Providência"
                      variant="outlined"
                      size="small"
                      style={{ width: '100%'  }}
                      multiline={true}
                      rows={7}
                      maxRows={7}
                      startAdornment={true}
                    />
                  </div>
                  
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <FrTextField 
                      maxLength='5000'
                      value={observacoes || ''}
                      onChange={handleChangeModal}
                      name="observacoes"
                      id="observacoes"
                      color="#49573B"
                      label="Observações"
                      variant="outlined"
                      size="small"
                      multiline={true}
                      rows={7}
                      maxRows={7}
                      style={{ width: '100%' }}
                      startAdornment={true}
                    />
                  </div>
                  
                </div>
                <div className="buttons" style={{marginTop: 8}}>
                  <button onClick={handleSubmitModal} style={{ marginBottom: 0}}>Salvar</button>
                  <button onClick={closeModal} style={{ marginBottom: 0}}>Cancelar</button>
                </div>

              </div>
            </div>
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Visitas;
